import Vue from 'vue';
import surePromise from "@utils/surePromise";
import menuManagerGalleryUtil from '@/utils/menuManagerGallery.js';
import requests from "@/helpers/requests";
import { TYPE_ACTION_MEDIA_MANAGER } from '@/constants';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import STORE from "@/store/types";

export default Vue.extend({
  name: 'MediaDescriptionAlbums',

  components: {
    MediaManager: () => import('@components/generals/MediaDescription/MediaManager'),
    Swiper,
    SwiperSlide
  },

  data: () => ({
    swiperOption: {},
    actionDeleteAlbum: TYPE_ACTION_MEDIA_MANAGER.DELETE_ALBUM
  }),

  computed: {
    getAlbumsStore() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_ALBUM];
    }
  },

  created() {
    this.swiperOption = {
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };
  },

  async updated() {
    if (this.getAlbumsStore.length === 1) {
      await this.setDataPanoramicPictures(this.getAlbumsStore[0].id);
    }
  },

  methods: {
    async setDataPanoramicPictures(albumId) {
     await this.$store.dispatch(STORE.ACTIONS.ALBUMS.RENDER_PANORAMICS, false);
      const { ok, result } = await surePromise(requests.getAlbumPicture({ belongsTo: albumId }));
      if (ok) await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_PANORAMIC, result);
      await this.$store.dispatch(STORE.ACTIONS.ALBUMS.RENDER_PANORAMICS, true);
    },

    handlerMediaManagerAlbum(id) {
      menuManagerGalleryUtil(id);
    }
  }
});
